<template lang="pug">
    unauthorized-layout
        template(v-slot:main-content)
            .w-full.flex.justify-center.items-center
                login-form
</template>

<script>
//Components
import UnauthorizedLayout from '@/layouts/unauthorized-layout';
import LoginForm from '@/components/views/login/';

export default {
    name: "HomePage",
    components: {
        'unauthorized-layout': UnauthorizedLayout,
        'login-form': LoginForm,
    }
}
</script>