<template lang="pug">
    .container.w-96.p-4.border.shadow-sm.bg-white
        text-input(:label="FORMS_LABELS.email" @value="onEmail" class="mb-2")
        text-input(:label="FORMS_LABELS.password" input-type="password" @value="onPassword" class="mb-4")
        regular-button(
            value="войти"
            @click="onLogin($data)"
        )
</template>

<script>
//Components
import TextInput from '@/components/common/forms/text-input';
import RegularButton from '@/components/common/buttons/regular-button';
//Store
import { AuthorizationNameSpace, AuthorizationActionTypes } from '@/store/authorization/types';
//Config
import { FORMS_LABELS, BUTTONS_VALUES } from '@/configs/names.js'

export default {
    name: "LoginForm",
    components: {
        'text-input': TextInput,
        'regular-button': RegularButton,
    },
    data() {
        return {
            email: '',
            password: '',
        }
    },
    computed: {
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
    },
    methods: {
        onLogin({ email, password }) {            
            this.$store.dispatch(`${AuthorizationNameSpace}/${AuthorizationActionTypes.Login}`, { email, password }).then(({data}) => {
                if (data.error_message) { this.email = '', this.password = '' }
            })
        },
        onEmail(value) {
            this.email = value
        },
        onPassword(value) {
            this.password = value
        }
    }
}
</script>