<template lang="pug">
    main-layout
        // - header
        template(v-slot:header-left)
            logo
        template(v-slot:header-center)
        template(v-slot:header-right)
        // - main content
        template(v-slot:main-content)
            slot(name="main-content")
        // - footer
        template(v-slot:footer-left)
            section 
        template(v-slot:footer-center)
            section 
        template(v-slot:footer-right)
            section 
</template>

<script>
//Components
import MainLayout from './main-layout.vue';
import Logo from '@/components/common/logo/Logo'

export default {
    name: "AuthorizedLayout",
    components: {
        'main-layout': MainLayout,
        'logo': Logo,
    },
}
</script>